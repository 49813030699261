import { Suspense, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import SplashScreen from './pages/splash';
import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAuth0 } from '@auth0/auth0-react';
import DynamicRoutes from './features/navigation/dynamic-routes';
import { getUserMetadata } from './services/userService';
import { useDispatch, useSelector } from 'react-redux';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material';
import colors from 'tailwindcss/colors';
import { userActions } from './redux/userSlice';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
const App = () => {
  const themeMode = useSelector((state) => state.user.themeMode);
  const themeColor = useSelector((state) => state.user.themeColor);

  const lightTheme = createTheme({
    palette: {
      background: {
        default:
          themeColor !== 'none' ? colors[themeColor][50] : colors.fuchsia[50],
        paper:
          themeColor !== 'none' ? colors[themeColor][50] : colors.fuchsia[50],
      },
      primary: {
        main:
          themeColor !== 'none' ? colors[themeColor][500] : colors.fuchsia[500],
      },
    },
  });

  const darkTheme = createTheme({
    palette: {
      background: {
        default:
          themeColor !== 'none' ? colors[themeColor][900] : colors.fuchsia[900],
        paper:
          themeColor !== 'none' ? colors[themeColor][900] : colors.fuchsia[900],
      },
      mode: 'dark',
      primary: {
        main:
          themeColor !== 'none' ? colors[themeColor][200] : colors.fuchsia[200],
      },
    },
  });

  const { isLoading, isAuthenticated, logout } = useAuth0();
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const res = await getUserMetadata({ has_booked_onboarding: false });
        dispatch(userActions.setUserMetaData(res.data));
      } catch (error) {
        toast.error('Error Occured');
      } finally {
        setLoading(false);
      }
    };
    if (!isLoading) {
      if (isAuthenticated) {
        fetchData();
      } else {
        setLoading(false);
      }
    }
  }, [dispatch, isAuthenticated, isLoading]);

  return (
    <PayPalScriptProvider
      options={{
        clientId:
          'AcTLt6R2c_cTq1UXOAx48GmMVSbDqS1WU_5B2hhCw9-jJp1MKibqKymXx30Lo9g0-JyLbgE-VPmM0AHZ',
        currency: 'USD',
        components: 'buttons',
        intent: 'subscriptions',
        vault: true,
      }}
    >
      <BrowserRouter>
        <ThemeProvider theme={themeMode === 'light' ? lightTheme : darkTheme}>
          <CssBaseline />
          <div
            id=""
            className={`theme-${themeColor} theme-${themeMode}  mx-auto `}
          >
            {loading ? (
              <SplashScreen />
            ) : (
              <div className="">
                <Suspense fallback={<SplashScreen />}>
                  <DynamicRoutes />
                </Suspense>
              </div>
            )}
            <ToastContainer />
          </div>
        </ThemeProvider>
      </BrowserRouter>
    </PayPalScriptProvider>
  );
};

export default App;
